<template>
  <div class="wrapper  wrapper--default p-2 m-1">
    <Loading v-if="loading" />
    <div v-else>
      <Tabs>
        <Tab title="Info" active>
          <h3 class="text-center">
            Leverancier: {{ leverancier_data.nummer }}
          </h3>
          <div class="d-flex flex-row m-2">
            <div class="flex-grow-1 m-1">
              <TextBox :label="`Nummer`" :value="`${leverancier_data.nummer}`" />
              <TextBox :label="`naam`" :value="`${leverancier_data.naam}`" />
              <TextBox :label="`adres`" :value="`${leverancier_data.adres}`" />
              <TextBox :label="`plaats`" :value="`${leverancier_data.stad}`" />
              <br />
              <router-link :to="{
                name: 'supplierstocksolditems',
                params: { levnr: id },
              }">
                <strong>Overzicht: </strong>
                <p>Huidige voorraad/verkopen</p>
              </router-link>
            </div>
            <div class="flex-grow-1 m-1">
              <TextBox :label="`postcode`" :value="`${leverancier_data.postcode}`" />
              <TextBox :label="`categorie`" :value="`${leverancier_data.categorie}`" />
              <TextBox :label="`contactinfo`" :value="`${leverancier_data.contactinfo}`" />
              <TextBox :label="`contact`" :value="`${leverancier_data.contactpersoon}`" />
            </div>
            <div class="flex-grow-2">
              <table class="table__border--default m-1">
                <thead class="table__head--default">
                  <tr>
                    <th class="table__head--text">Jaar</th>
                    <th class="table__head--text">Aantal</th>
                    <th class="table__head--text">Inkopen</th>
                    <th class="table__head--text">Winst</th>
                    <th class="table__head--text">Gemiddeld</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in leverancier_history.lev_history" :key="key" :class="`table__row--color`">
                    <td class="table__cell--default">{{ item.jaar }}</td>
                    <td class="table__cell--default">{{ item.aantal }}</td>
                    <td class="table__cell--default">€ {{ item.inkopen }}</td>
                    <td class="table__cell--default">€ {{ item.winst }}</td>
                    <td class="table__cell--default">€ {{ item.gemwinst }}</td>
                  </tr>
                </tbody>
                <tfoot class="table__foot">
                  <th class="table__foot--padding">totaal:</th>
                  <th class="table__foot--padding">
                    {{ leverancier_history.lev_history_total.aantal }}
                  </th>
                  <th class="table__foot--padding">
                    € {{ leverancier_history.lev_history_total.inkopen }}
                  </th>
                  <th class="table__foot--padding">
                    € {{ leverancier_history.lev_history_total.winst }}
                  </th>
                  <th class="table__foot--padding">
                    € {{ leverancier_history.lev_history_total.gemiddeld }}
                  </th>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="d-flex flex-row mt-3">
            <div class="flex-grow-2">
              <h4 class="text-center">Contact informatie</h4>
              <table class="table__border--default">
                <thead class="table__head--default">
                  <tr>
                    <th class="table__head--text">Naam</th>
                    <th class="table__head--text">E-mail</th>
                    <th class="table__head--text">Soort</th>
                    <th class="table__head--text">Telefoon nummer</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, key) in leverancier_contactinfo" :key="key" :class="`table__row--color`">
                    <td class="table__cell--default">{{ item.naam }}</td>
                    <td class="table__cell--default">{{ item.email }}</td>
                    <td class="table__cell--default">{{ item.soort }}</td>
                    <td class="table__cell--default">{{ item.telefoon }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Tab>
        <Tab title="Openstaande posten">
          <div>
            <table class="w-100 table--default table__border--default">
              <thead class="table__head--default">
                <tr>
                  <th class="table__head--text">Doc type</th>
                  <th class="table__head--text">Doc nummer</th>
                  <th class="table__head--text">Boek datum</th>
                  <th class="table__head--text">Factuur datum</th>
                  <th class="table__head--text">Totaal bedrag</th>
                  <th class="table__head--text">Openstaand bedrag</th>
                  <th class="table__head--text">Omschrijving</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in leverancier_open_posts" :key="key" class="table__row--color">
                  <td class="table__cell--default">{{ item.doctype }}</td>
                  <td class="table__cell--default">{{ item.docnummer }}</td>
                  <td class="table__cell--default">{{ item.boekdatum }}</td>
                  <td class="table__cell--default">{{ item.factuurdatum }}</td>
                  <td class="table__cell--default">€ {{ item.bedrag }}</td>
                  <td class="table__cell--default">€ {{ item.openbedrag }}</td>
                  <td class="table__cell--default">{{ item.omschrijving }}</td>
                </tr>
              </tbody>
              <tfoot class="table__foot">
                <tr>
                  <th class="table__foot--padding">Totaal:</th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding">
                    € {{ leverancier_open_posts_total.total_amount }}
                  </th>
                  <th class="table__foot--padding">
                    € {{ leverancier_open_posts_total.open_amount }}
                  </th>
                  <th class="table__foot--padding"></th>
                </tr>
              </tfoot>
            </table>
          </div>
        </Tab>
        <Tab title="Item Historie">
          <div>
            <table class="w-100 table--default table__border--default">
              <thead class="table__head--default">
                <tr>
                  <th class="table__head--text">Nummer</th>
                  <th class="table__head--text">Bu</th>
                  <th class="table__head--text">Soort</th>
                  <th class="table__head--text">Merk & type</th>
                  <th class="table__head--text">Jaar</th>
                  <th class="table__head--text">Inkoper</th>
                  <th class="table__head--text">Status</th>
                  <th class="table__head--text">Order datum</th>
                  <th class="table__head--text">Ontvangst datum</th>
                  <th class="table__head--text">Inkopen</th>
                  <th class="table__head--text">Winst</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in leverancier_item_historie" :key="key" class="table__row--color">
                  <td class="table__cell--default">
                    <ImageHover :itemnummer="item.nummer" :bu="item.bu" />
                  </td>
                  <td class="table__cell--default">{{ item.bu }}</td>
                  <td class="table__cell--default">{{ item.soort }}</td>
                  <td class="table__cell--default">{{ item.merk }}</td>
                  <td class="table__cell--default">{{ item.regjaar }}</td>
                  <td class="table__cell--default">{{ item.inkoper }}</td>
                  <td class="table__cell--default">{{ item.status }}</td>
                  <td class="table__cell--default">{{ item.orderdatum }}</td>
                  <td class="table__cell--default">
                    {{ item.ontvangstdatum }}
                  </td>
                  <td class="table__cell--default">€ {{ item.inkopen }}</td>
                  <td class="table__cell--default">€ {{ item.winst }}</td>
                </tr>
              </tbody>
              <tfoot class="table__foot">
                <tr>
                  <th class="table__foot--padding">Totaal:</th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding">
                    {{ leverancier_item_totals.aantal }}
                  </th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding"></th>
                  <th class="table__foot--padding">
                    € {{ leverancier_item_totals.inkopen }}
                  </th>
                  <th class="table__foot--padding">
                    € {{ leverancier_item_totals.winst }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import TextBox from "@/components/TextBox.vue";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";

export default {
  props: ["id"],
  components: { TextBox, Loading, ImageHover, Tabs, Tab },
  data: () => ({
    loading: true,
    leverancier_data: null,
    leverancier_history: null,
    leverancier_contactinfo: null,
    leverancier_item_historie: null,
    leverancier_item_totals: null,
    leverancier_open_posts: null,
    leverancier_open_posts_total: null,
  }),
  created() {
    this.getData(this.id);
  },
  methods: {
    getData(id) {
      request(`leverancier/${id}`, "GET").then(
        ({
          leverancier_data,
          leverancier_history,
          leverancier_contactinfo,
        }) => {
          this.leverancier_data = leverancier_data[0];
          this.leverancier_history = leverancier_history;
          this.leverancier_contactinfo = leverancier_contactinfo;
        }
      );

      request(`leverancier-item-historie/${id}`, "GET").then(
        ({ leverancier_item_historie, leverancier_item_totals }) => {
          this.leverancier_item_historie = leverancier_item_historie;
          this.leverancier_item_totals = leverancier_item_totals;
        }
      ),
        request(`leverancier-open-posts/${id}`, "GET").then(
          ({ leverancier_open_posts, leverancier_open_posts_total }) => {
            this.leverancier_open_posts = leverancier_open_posts;
            this.leverancier_open_posts_total = leverancier_open_posts_total;
            this.loading = false;
          }
        );
    },
  },
};
</script>
